<template>
  <div>
    {{ /*search trade modal*/ }}
    <b-modal
      id="searchTradeModal"
      v-model="searchForTradesModal"
      :title="$t('equity_bond.titles.search_for_trade')"
      @hidden="resetSearchForTradesModal"
      size="xl"
      :ok-title="$t('equity_bond.modals.search_trade_modal_ok')"
      :cancel-title="$t('equity_bond.modals.modal_cancel')"
      @ok="searchForTrades"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="searchTradeModal"
      body-class="compact_form_modal"
      footer-class="compact_modal_footer"
      no-close-on-backdrop
    >
      <b-overlay :show="searchTradesloading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="searchValidation">
            <b-form>
              <!-- /*******************************Basic Search***************************************************************** */ -->
              <b-row class="mt-1">
                <b-card :title="$t('equity_bond.titles.basic_search')">
                  <b-row>
                    <!-- client-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.client')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.client')"
                        >
                          <v-select
                            v-model="tradeForm.client"
                            :options="optClients"
                            :clearable="true"
                            :placeholder="
                              $t('equity_bond.trade_form.select_client')
                            "
                            :reduce="(val) => val"
                            :state="errors.length > 0 ? false : null"
                            :loading="loadingClient"
                            @option:selecting="selectedClient"
                            @input="checkAccount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- client account-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.client_account')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.client_account')"
                        >
                          <b-form-input
                            id="clientAccount"
                            v-model="tradeForm.clientAccount"
                            name="clientAccount"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('equity_bond.trade_form.client_account')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- counterparty-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.counterparty')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.counterparty')"
                        >
                          <v-select
                            v-model="tradeForm.counterparty"
                            :options="optCounterParties"
                            :clearable="true"
                            :placeholder="
                              $t('equity_bond.trade_form.select_counterparty')
                            "
                            :reduce="(val) => val"
                            :loading="loadingCounterparty"
                            @option:selecting="selectedCounterparty"
                            @input="checkCptyAccount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counterparty account-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cpty_account')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_account')"
                        >
                          <b-form-input
                            id="isin"
                            v-model="tradeForm.cptyAccount"
                            name="cpty account"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="
                              $t('equity_bond.trade_form.cpty_account')
                            "
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- order date-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.order_date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_date')"
                        >
                          <b-input-group>
                            <flat-pickr
                              :placeholder="
                                $t('equity_bond.trade_form.order_date')
                              "
                              v-model="orderDateRange"
                              onClose="testClose"
                              class="form-control "
                              :config="orderDateConfig"
                              :state="errors.length > 0 ? false : null"
                              @on-change="checkOrdersDate('Order Date')"
                            />
                            <b-input-group-append>
                              <b-button
                                @click="clearOrderDate()"
                                size="sm"
                                variant="secondary"
                                >{{ $t("general_buttons.clear") }}</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- settlement date-->
                    <b-col cols="6" xl="2" lg="2" md="3" sm="6">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.settlement_date')"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.settlement_date')"
                        >
                          <b-input-group>
                            <flat-pickr
                              :placeholder="
                                $t('equity_bond.trade_form.settlement_date')
                              "
                              v-model="settleDateRange"
                              onClose="testClose"
                              class="form-control "
                              :config="dateConfig"
                              @on-change="
                                checkTraSettlCptyDate('Settlement Date')
                              "
                            />
                            <b-input-group-append>
                              <b-button
                                @click="clearSettleDate()"
                                size="sm"
                                variant="secondary"
                                >{{ $t("general_buttons.clear") }}</b-button
                              >
                            </b-input-group-append>
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-row>
              <b-button
                v-b-toggle.collapse-1
                variant="outline-primary"
                @click="
                  updownButtonText == 'down'
                    ? (updownButtonText = 'up')
                    : (updownButtonText = 'down')
                "
                size="sm"
              >
                <i :class="'fa-solid fa-angles-' + updownButtonText"></i>
                {{ $t("equity_bond.titles.advanced_search") }}
              </b-button>

              <b-collapse id="collapse-1" class="mt-1">
                <b-row>
                  <!--------------------- order details ---------------------------------->
                  <b-card :title="$t('equity_bond.titles.order_details')">
                    <b-row>
                      <!-- order time-->
                      <b-col cols="2" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.order_time')"
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                class="form-control "
                                v-model="tradeForm.orderTime"
                                :options="cleaveOptions.time"
                                @blur.native="checkTime('Order Time')"
                                :placeholder="$t('equity_bond.trade_form.min')"
                              />
                              <cleave
                                class="form-control "
                                v-model="tradeForm.orderTimeMax"
                                :options="cleaveOptions.time"
                                @blur.native="checkTime('Order Time')"
                                :placeholder="$t('equity_bond.trade_form.max')"
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col cols="2" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.order_duration_date')
                          "
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="
                              $t('equity_bond.trade_form.order_duration_date')
                            "
                          >
                            <b-input-group>
                              <flat-pickr
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.order_duration_date'
                                  )
                                "
                                v-model="durationDateRange"
                                onClose="testClose"
                                class="form-control "
                                :config="dateConfig"
                                :state="errors.length > 0 ? false : null"
                                @on-change="
                                  checkOrdersDate('Order Duration Date')
                                "
                              />
                              <b-input-group-append>
                                <b-button
                                  @click="clearDurationDate()"
                                  size="sm"
                                  variant="secondary"
                                  >{{ $t("general_buttons.clear") }}</b-button
                                >
                              </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col cols="2" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.order_duration_time')
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                class="form-control "
                                v-model="tradeForm.orderDurationTime"
                                :options="cleaveOptions.time"
                                @blur.native="
                                  checkOrdersTime('Order Duration Time')
                                "
                                :placeholder="$t('equity_bond.trade_form.min')"
                              />
                              <cleave
                                class="form-control "
                                v-model="tradeForm.orderDurationTimeMax"
                                :options="cleaveOptions.time"
                                @blur.native="
                                  checkOrdersTime('Order Duration Time')
                                "
                                :placeholder="$t('equity_bond.trade_form.max')"
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- entry type -->
                      <b-col cols="2" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.entry_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.entry_type')"
                          >
                            <v-select
                              v-model="tradeForm.entryType"
                              :options="optEntryTypes"
                              :clearable="true"
                              :placeholder="
                                $t('equity_bond.trade_form.all_entry')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- buy/sell -->
                      <b-col cols="2" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.buy_sell')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.buy_sell')"
                          >
                            <v-select
                              v-model="tradeForm.buySell"
                              :options="optbuySells"
                              :clearable="true"
                              :placeholder="
                                $t('equity_bond.trade_form.select_buy_sell')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- time in force-->
                      <b-col cols="2" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.time_in_force')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.time_in_force')"
                          >
                            <v-select
                              v-model="tradeForm.timeInForce"
                              :options="optTimeInForces"
                              :clearable="true"
                              :multiple="true"
                              :placeholder="
                                $t(
                                  'equity_bond.trade_form.select_time_in_force'
                                )
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                              @option:selected="changeTimeInForceType"
                              class="compact_form_vue-multi-select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order type-->
                      <b-col cols="2" xl="2" lg="2" md="2" sm="12">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.order_type')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_type')"
                          >
                            <v-select
                              v-model="tradeForm.orderType"
                              :options="optOrderTypes"
                              :clearable="true"
                              :placeholder="
                                $t('equity_bond.trade_form.select_order_type')
                              "
                              :reduce="(val) => val"
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Stop Price Start && End -->

                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        v-if="
                          tradeForm.orderType == 'Stop' ||
                            tradeForm.orderType == 'Trailing Stop'
                        "
                      >
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_price')"
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="stopPriceStart"
                                v-model.number="tradeForm.stopPrice"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.stop_price')
                                "
                              />
                              <cleave
                                id="stopPriceEnd"
                                v-model.number="tradeForm.stopPriceEnd"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                  $t('equity_bond.trade_form.stop_price')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>

                      <!-- Trailing Amount Start && End -->

                      <b-col
                        cols="6"
                        xl="2"
                        lg="2"
                        md="4"
                        sm="6"
                        v-if="tradeForm.orderType == 'Trailing Stop'"
                      >
                        <b-form-group
                          :label="$t('equity_bond.trade_form.trailing_amount')"
                        >
                          <div>
                            <b-input-group>
                              <b-form-input
                                id="trailingAmountStart"
                                v-model.number="tradeForm.trailingAmount"
                                name="trailingAmount"
                                type="number"
                                :placeholder="$t('equity_bond.trade_form.min')"
                              />
                              <b-form-input
                                id="trailingAmountEnd"
                                v-model.number="tradeForm.trailingAmountEnd"
                                name="trailingAmount"
                                type="number"
                                :placeholder="$t('equity_bond.trade_form.max')"
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-row style="margin-top: 0.5rem;">
                      <!-- enable connected OCO orders-->
                      <b-col cols="6" xl="2" lg="2" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.enable_oco')"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="enable OCO orders"
                          >
                            <v-select
                              label="title"
                              v-model="tradeForm.enableConnectedOCOOrders"
                              :options="optEnableOCOs"
                              :clearable="false"
                              :reduce="(val) => val"
                              class="compact_form_vue-select"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <!-- Take Profit Limit -->
                      <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                        <b-form-group
                          :label="
                            $t('equity_bond.trade_form.take_profit_limit')
                          "
                          v-if="
                            tradeForm.enableConnectedOCOOrders ==
                              'Enable Connected OCO Orders'
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="takeProfitLimit"
                                v-model.number="tradeForm.takeProfitLimit"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.min')"
                                @blur.native="
                                  checkEnableOCOInputs('takeProfitLimit')
                                "
                              />
                              <cleave
                                id="takeProfitLimit"
                                v-model.number="tradeForm.takeProfitLimitEnd"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.max')"
                                @blur.native="
                                  checkEnableOCOInputs('takeProfitLimit')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Stop -->
                      <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_loss_stop')"
                          v-if="
                            tradeForm.enableConnectedOCOOrders ==
                              'Enable Connected OCO Orders'
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="stopLossStop"
                                v-model.number="tradeForm.stopLossStop"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.min')"
                                @blur.native="
                                  checkEnableOCOInputs('stopLossStop')
                                "
                              />
                              <cleave
                                id="stopLossStop"
                                v-model.number="tradeForm.stopLossStopEnd"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.max')"
                                @blur.native="
                                  checkEnableOCOInputs('stopLossStop')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>

                      <!-- Stop Loss Limit -->
                      <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                        <b-form-group
                          :label="$t('equity_bond.trade_form.stop_loss_limit')"
                          v-if="
                            tradeForm.enableConnectedOCOOrders ==
                              'Enable Connected OCO Orders'
                          "
                        >
                          <div>
                            <b-input-group>
                              <cleave
                                id="stopLossLimit"
                                v-model.number="tradeForm.stopLossLimit"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.min')"
                                @blur.native="
                                  checkEnableOCOInputs('stopLossLimit')
                                "
                              />
                              <cleave
                                id="stopLossLimit"
                                v-model.number="tradeForm.stopLossLimitEnd"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.max')"
                                @blur.native="
                                  checkEnableOCOInputs('stopLossLimit')
                                "
                              />
                            </b-input-group>
                          </div>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-row>

                <b-row>
                  <!-- /******************************* Trade Date & Time && Security Details***************************************************************** */ -->

                  <b-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                    class="pl-0"
                    style="padding-right:0.5rem!important"
                  >
                    <!-- /******************************* Security Details***************************************************************** */ -->
                    <b-card :title="$t('equity_bond.titles.security_details')">
                      <b-row>
                        <!-- cost center -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.cost_center')"
                          >
                            <v-select
                              v-model="tradeForm.costCenter"
                              :options="optCostCenters"
                              :clearable="true"
                              :placeholder="
                                $t('equity_bond.trade_form.select_cost_center')
                              "
                              :reduce="(val) => val.CostCenter"
                              label="CostCenter"
                              class="compact_form_vue-select"
                            />
                          </b-form-group>
                        </b-col>

                        <!-- security desc-->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.security_desc')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.security_desc')"
                            >
                              <v-select
                                v-model="tradeForm.securityDesc"
                                :options="optSecurityDescriptions"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_security_desc'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                :loading="loadingSecurityDesc"
                                @option:selecting="selectedSecuritydesc"
                                class="compact_form_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- ISIN -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.isin')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              name="ISIN"
                            >
                              <b-form-input
                                id="isin"
                                v-model="tradeForm.isin"
                                :name="$t('equity_bond.trade_form.isin')"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.isin')"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- quantity -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.quantity')"
                          >
                            <div>
                              <b-input-group>
                                <b-form-input
                                  id="quantityMin"
                                  v-model.number="tradeForm.quantity"
                                  name="quantity"
                                  type="number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <b-form-input
                                  id="quantityMax"
                                  v-model.number="tradeForm.quantityEnd"
                                  name="quantity"
                                  type="number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- client price -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.client_price')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.clientPrice"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientPrice"
                                  v-model.number="tradeForm.clientPriceEnd"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- client price type -->
                        <b-col cols="6" xl="3" lg="3" md="4" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.price_type')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.price_type')"
                            >
                              <v-select
                                v-model="tradeForm.clientPriceType"
                                :options="optClientPriceTypes"
                                :clearable="true"
                                :placeholder="
                                  $t('equity_bond.trade_form.price_type')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                class="compact_form_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- amount -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.amount')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="amount"
                                  v-model.number="tradeForm.amount"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="amount"
                                  v-model.number="tradeForm.amountEnd"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>

                        <!-- exchange traded -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.exchange_traded')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.exchange_traded')
                              "
                            >
                              <v-select
                                v-model="tradeForm.exchangeTraded"
                                :options="optExchangeTrades"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_exchange_traded'
                                  )
                                "
                                :reduce="(val) => val"
                                class="compact_form_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- counter party trader -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.counterparty_trader')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.counterparty_trader')
                              "
                            >
                              <v-select
                                v-model="tradeForm.counterpartyTrader"
                                :options="optCounterPartyTraders"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_counterparty_trader'
                                  )
                                "
                                :reduce="(val) => val"
                                class="compact_form_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- sfc user -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.sfc_user')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.sfc_user')"
                            >
                              <v-select
                                v-model="tradeForm.scfUser"
                                :options="optScfUsers"
                                :clearable="true"
                                :placeholder="
                                  $t('equity_bond.trade_form.select_sfc_user')
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                class="compact_form_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- acting capacity -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.acting_capacity')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.acting_capacity')
                              "
                            >
                              <v-select
                                v-model="tradeForm.actingCapaticy"
                                :options="optActingCapacities"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_acting_capacity'
                                  )
                                "
                                :reduce="(val) => val"
                                :state="errors.length > 0 ? false : null"
                                class="compact_form_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>

                    <!-- /******************************* Trade Date & Time***************************************************************** */ -->

                    <b-card :title="$t('equity_bond.titles.trade_date_time')">
                      <b-row>
                        <!-- Trade Date -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.trade_date')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_date')"
                            >
                              <b-input-group>
                                <flat-pickr
                                  :placeholder="
                                    $t('equity_bond.trade_form.trade_date')
                                  "
                                  v-model="tradeDateRange"
                                  onClose="testClose"
                                  class="form-control "
                                  :config="dateConfig"
                                  @on-change="
                                    checkTraSettlCptyDate('Trade Date')
                                  "
                                />
                                <b-input-group-append>
                                  <b-button
                                    @click="clearTradeDate()"
                                    size="sm"
                                    variant="secondary"
                                    >{{ $t("general_buttons.clear") }}</b-button
                                  >
                                </b-input-group-append>
                              </b-input-group>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- execution time -->
                        <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.execution_time')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  class="form-control "
                                  v-model="tradeForm.executionTime"
                                  :options="cleaveOptions.time"
                                  @blur.native="checkTime('Execution Time')"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  class="form-control "
                                  v-model="tradeForm.executionTimeMax"
                                  :options="cleaveOptions.time"
                                  @blur.native="checkTime('Execution Time')"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>
                  </b-col>

                  <!-- /******************************* Trade Details && Settlement Details ***************************************************************** */ -->
                  <b-col
                    cols="12"
                    sm="12"
                    md="12"
                    lg="6"
                    xl="6"
                    class="pr-0"
                    style="padding-left:0.5rem!important"
                  >
                    <!-- /******************************* Trade Details***************************************************************** */ -->

                    <b-card :title="$t('equity_bond.titles.trade_details')">
                      <b-row>
                        <!-- Client Rate -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.client_rate')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientRate"
                                  v-model.number="tradeForm.clientRate"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientRate"
                                  v-model.number="tradeForm.clientRateEnd"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- Client Commission -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.client_comission')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientCommission"
                                  v-model.number="tradeForm.clientCommission"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientCommission"
                                  class="form-control "
                                  v-model.number="tradeForm.clientCommissionEnd"
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- Client amount -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.client_amount')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="clientAmmount"
                                  v-model.number="tradeForm.clientAmount"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="clientAmmount"
                                  v-model.number="tradeForm.clientAmountEnd"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- Counterparty Rate -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.counterparty_rate')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="counterpartyRate"
                                  v-model.number="tradeForm.counterpartyRate"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="counterpartyRate"
                                  v-model.number="tradeForm.counterpartyRateEnd"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- cpty comission -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.cpty_comission')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="cptyComission"
                                  v-model.number="tradeForm.cptyCommission"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="cptyComission"
                                  v-model.number="tradeForm.cptyCommissionEnd"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- counterparty amount -->
                        <b-col cols="6" xl="4" lg="4" md="6" sm="6">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.counterparty_amount')
                            "
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="counterpartyAmount"
                                  v-model.number="tradeForm.counterpartyAmount"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="counterpartyAmount"
                                  v-model.number="
                                    tradeForm.counterpartyAmountEnd
                                  "
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- profit -->
                        <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.profit')"
                          >
                            <div>
                              <b-input-group>
                                <cleave
                                  id="profit"
                                  v-model.number="tradeForm.profit"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.min')
                                  "
                                />
                                <cleave
                                  id="profit"
                                  v-model.number="tradeForm.profitEnd"
                                  class="form-control "
                                  :raw="true"
                                  :options="cleaveOptions.number"
                                  :placeholder="
                                    $t('equity_bond.trade_form.max')
                                  "
                                />
                              </b-input-group>
                            </div>
                          </b-form-group>
                        </b-col>
                        <!-- order taken via -->
                        <b-col cols="3" xl="3" lg="3" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.order_taken_via')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.order_taken_via')
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderTakenVia"
                                :options="optOrderTakenVias"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_order_taken_via'
                                  )
                                "
                                :reduce="(val) => val"
                                class="compact_form_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken through -->
                        <b-col cols="3" xl="3" lg="3" md="4" sm="12">
                          <b-form-group
                            :label="
                              $t('equity_bond.trade_form.order_given_through')
                            "
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="
                                $t('equity_bond.trade_form.order_given_through')
                              "
                            >
                              <v-select
                                v-model="tradeForm.orderGivenThrough"
                                :options="optOrderGivenThroughs"
                                :clearable="true"
                                :placeholder="
                                  $t(
                                    'equity_bond.trade_form.select_order_given_through'
                                  )
                                "
                                :reduce="(val) => val"
                                class="compact_form_vue-select"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>

                        <!-- tax min -->
                        <!-- <b-col cols="6" xl="3" lg="3" md="6" sm="6">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.tax')"
                            
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.tax_start')"
                            >
                           

                              <b-input-group>
                            <cleave
                                id="profit"
                                v-model.number="tradeForm.tax"
                                class="form-control "
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="
                                $t('equity_bond.trade_form.tax_start')
                              "
                         
                              />
                              <cleave
                              id="profit"
                              v-model.number="tradeForm.taxEnd"
                              class="form-control "
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.tax_end')
                              "
                         
                            />
                          </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col> -->

                        <!-- note -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group
                            :label="$t('equity_bond.trade_form.note')"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.note')"
                            >
                              <b-form-textarea
                                id="form-textarea"
                                :placeholder="$t('equity_bond.trade_form.note')"
                                no-resize
                                v-model="tradeForm.note"
                                class="compact_form-textarea"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </b-card>

                    <!-- /******************************* Settlement Details***************************************************************** */ -->
                    <b-col
                      cols="12"
                      sm="12"
                      mf="12"
                      lg="12"
                      xl="12"
                      class="pl-0 pr-0"
                    >
                      <b-card title="Settlement Details">
                        <b-row>
                          <!-- UTI  -->
                          <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                            <b-form-group
                              :label="$t('equity_bond.trade_form.uti')"
                            >
                              <validation-provider
                                #default="{ errors }"
                                :name="$t('equity_bond.trade_form.uti')"
                              >
                                <b-form-input
                                  id="UTI"
                                  v-model="tradeForm.uti"
                                  name="UTI"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="
                                    $t('equity_bond.trade_form.uti')
                                  "
                                />

                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- Unique Link ID -->
                          <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                            <b-form-group
                              :label="
                                $t('equity_bond.trade_form.unique_link_id')
                              "
                            >
                              <validation-provider
                                #default="{ errors }"
                                :name="
                                  $t('equity_bond.trade_form.unique_link_id')
                                "
                              >
                                <b-form-input
                                  id="uniqueLinkID"
                                  v-model.number="tradeForm.uniqueLinkID"
                                  type="number"
                                  name="uniqueLinkID"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="
                                    $t('equity_bond.trade_form.unique_link_id')
                                  "
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                          <!-- Trade Ref -->
                          <b-col cols="6" xl="4" lg="4" md="4" sm="6">
                            <b-form-group
                              :label="$t('equity_bond.trade_form.trade_ref')"
                            >
                              <validation-provider
                                #default="{ errors }"
                                :name="$t('equity_bond.trade_form.trade_ref')"
                              >
                                <b-form-input
                                  id="tradeRef"
                                  v-model="tradeForm.tradeRef"
                                  name="tradeRef"
                                  :state="errors.length > 0 ? false : null"
                                  :placeholder="
                                    $t('equity_bond.trade_form.trade_ref')
                                  "
                                />
                                <small class="text-danger">{{
                                  errors[0]
                                }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="searchTradesloading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="cancel-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t("equity_bond.overlay_messages.searching_message") }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      {{/****END*** search for trades modal*/}}

      <!-- modal footer -->
      <template #modal-footer>
        <b-row class="align-items-center" style="flex: 1;">
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-start align-items-center"
          >
            <b-overlay
              :show="loadingSavedSearch"
              rounded="lg"
              opacity="0.6"
              class="d-flex align-items-center"
            >
              <template v-slot:overlay>
                <div class="d-flex align-items-center">
                  <b-spinner small type="grow" variant="secondary" />
                  <b-spinner type="grow" variant="dark" />
                  <b-spinner small type="grow" variant="secondary" />
                  <!-- We add an SR only text for screen readers -->
                  <span class="sr-only">Please wait...</span>
                </div>
              </template>

              <b-form-checkbox
                v-model="showSearchInputs"
                class="float-right compact_form_checkbox"
                style="margin-right:0.8rem"
                plain
              >
                {{ "Save this search" }}
              </b-form-checkbox>

              <validation-observer
                ref="tradeSearchValidation"
                style="margin-right:0.5rem"
              >
                <validation-provider
                  #default="{ errors }"
                  :name="$t('general_title.default_entry_name')"
                  :rules="showSearchInputs ? 'required|min:3' : ''"
                >
                  <b-form-input
                    v-if="showSearchInputs"
                    v-model="searchName"
                    placeholder="Enter search name"
                    id="searchName"
                    name="searchName"
                    class="float-right animate__animated animate__fadeIn compact_form-input"
                    type="text"
                    autofocus
                    :state="errors.length > 0 ? false : null"
                  />
                </validation-provider>
              </validation-observer>

              <b-button
                class="btn gradient-info animate__animated animate__fadeIn"
                size="sm"
                variant="gradient-info"
                @click="saveSearch"
                v-if="showSearchInputs"
              >
                Save this search
              </b-button>
            </b-overlay>
          </b-col>

          <!-- trade ok operation buttons -->
          <b-col
            cols="12"
            md="12"
            xl="6"
            lg="6"
            sm="12"
            class="d-flex justify-content-end align-items-center"
          >
            <b-dropdown
              text="My Saved Searches"
              dropup
              right
              class="mr-1 animate__animated animate__fadeIn"
              variant="dark"
              size="sm"
              v-if="savedSearchesList.length > 0"
            >
              <b-dropdown-item
                v-for="(n, index) in savedSearchesList"
                :key="index"
                class="d-flex align-items-center"
              >
                <feather-icon
                  class="mr-1"
                  style="color:red;"
                  @click="deleteSavedSearch(n.id)"
                  icon="XIcon"
                  size="12"
                />
                <span @click="selectSavedSearch(n.SearchData)"
                  >{{ n.SearchName }}
                </span>
              </b-dropdown-item>
            </b-dropdown>

            <b-button
              v-b-modal.modal-close_visit
              class="btn btn-secondary mr-1"
              @click="resetSearchForTradesModal"
              size="sm"
            >
              {{ $t("equity_bond.modals.modal_cancel") }}
            </b-button>

            <b-button
              v-b-modal.modal-close_visit
              class="btn btn-primary mr-1"
              variant="primary"
              @click="searchForTrades"
              size="sm"
            >
              {{ $t("equity_bond.modals.search_trade_modal_ok") }}
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import equityStoreModule from "./EquityStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(
        EQUITYBONDS_APP_STORE_MODULE_NAME,
        equityStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},

  props: {
    searchedCounterparties: {
      type: Array,
      default: function() {
        return [];
      },
    },
    searchedClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCostCenters: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optClients: {
      type: Array,
      default: function() {
        return [];
      },
    },
    optCounterParties: {
      type: Array,
      default: function() {
        return [];
      },
    },
  },

  data() {
    return {
      allEquityBondsData: [],
      searchForTradesModal: false,
      searchTradesloading: false,
      loadingSecurityDesc: false,
      loadingClient: false,
      loadingCounterparty: false,
      timerSecuritydesc: null,
      orderDurationDataDisabled: true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      loadingSavedSearch: false,
      orderDateRange: null,
      tradeDateRange: null,
      settleDateRange: null,
      durationDateRange: null,

      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell", "Sell Short"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optInstrumentTypes: ["EQ", "FI"],
      optEnableOCOs: [
        "All Oco Orders",
        "Enable Connected OCO Orders",
        "Disable Connected OCO Orders",
      ],
      optSecurityDescriptions: [],
      optClientPriceTypes: [],
      optExchangeTrades: [],
      optCounterPartyTraders: [],

      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT", "MATCHED PRINCIPAL"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      searchName: null,
      optSearches: [],
      showSearchInputs: false,
      updownButtonText: "down",

      tradeForm: {
        costCenter: null,
        orderDurationDate: null,
        orderDurationDateEnd: null,

        orderDurationTime: null,
        orderDurationTimeMax: null,

        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderDateEnd: null,

        orderTime: null,
        orderTimeMax: null,
        cptyOrderDate: null,
        cptyOrderDateEnd: null,
        enableConnectedOCOOrders: "All Oco Orders",
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        instrumentType: "EQ",
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: null,
        tradeDate: null,
        tradeDateEnd: null,

        settlementDate: null,
        settlementDateEnd: null,

        executionTime: null,
        executionTimeMax: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        tax: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,

        stopPriceEnd: null,
        trailingAmountEnd: null,
        takeProfitLimitEnd: null,
        stopLossStopEnd: null,
        stopLossLimitEnd: null,
        quantityEnd: null,
        clientPriceEnd: null,
        amountEnd: null,
        calculateCommissionFromRate1End: null,
        clientRateEnd: null,
        clientCommissionEnd: null,
        clientAmountEnd: null,
        calculateCommissionFromRate2End: null,
        counterpartyRateEnd: null,
        cptyCommissionEnd: null,
        counterpartyAmountEnd: null,
        profitEnd: null,
        taxEnd: null,
      },
      timeConfig: {
        wrap: true,
        static: true,
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
      },
      dateConfig: {
        wrap: true,
        static: true,
        allowInput: true,
        dateFormat: "d-m-Y",
        mode: "range",
      },
      orderDateConfig: {
        wrap: true,
        static: true,
        allowInput: true,
        dateFormat: "d-m-Y",
        mode: "range",
        locale: {
          firstDayOfWeek: 1, // start week on Monday
        },
        // disable: [
        // function(date) {
        //     // return true to disable
        //     return (date.getDay() === 0 || date.getDay() === 6);

        // }
        // ],
        onDayCreate: function(dObj, dStr, fp, dayElem) {
          if ([0, 6].indexOf(dayElem.dateObj.getDay()) !== -1) {
            dayElem.classList.add("notAllowed");
          }
        },
        // onChange: function(selectedDates, dateStr, instance) {
        //   console.log(instance)
        // }
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave6DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 6,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
    savedSearchesList() {
      return this.optSearches;
    },
  },
  methods: {
    ...mapActions(["saveLastSearchEquity"]),

    deleteSavedSearch(id) {
      store
        .dispatch("equity&bond/deleteSavedSearch", {
          auth: this.user,
          id: id,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedSearches();

          this.successMessage(res.data.message);
        })
        .catch((error) => {
          this.errorMessage(error);

          console.log(error);
        });
    },

    saveSearch() {
      this.loadingSavedSearch = true;
      this.tradeForm.orderDateRange = this.orderDateRange;
      this.tradeForm.tradeDateRange = this.tradeDateRange;
      this.tradeForm.settleDateRange = this.settleDateRange;
      this.tradeForm.durationDateRange = this.durationDateRange;
      store
        .dispatch("equity&bond/saveSearch", {
          auth: this.user,
          searchData: this.tradeForm,
          searchName: this.searchName,
          searchType: "Equity",
          LastSearch: 0,
        })
        .then((res) => {
          this.searchName = null;
          this.getSavedSearches();

          this.successMessage(res.data.message);
          this.loadingSavedSearch = false;
        })
        .catch((error) => {
          this.errorMessage(error);
          this.loadingSavedSearch = false;
          console.log(error);
        });
    },

    selectSavedSearch(savedTrade) {
      if (savedTrade) {
        this.orderDateRange = savedTrade.orderDateRange;
        this.tradeDateRange = savedTrade.tradeDateRange;
        this.settleDateRange = savedTrade.settleDateRange;
        this.durationDateRange = savedTrade.durationDateRange;
        this.tradeForm = savedTrade;
      }
    },

    getSavedSearches() {
      store
        .dispatch("equity&bond/getSavedSearches", { SearchType: "Equity" })
        .then((res) => {
          if (res.data.savedSearches.length > 0) {
            this.optSearches = _.remove(res.data.savedSearches, {
              LastSearch: false,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getArangeEquityBondTCUsers() {
      store
        .dispatch("equity&bond/fetchArangeEquityBondTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
            this.getArrangeEquityBondOrderTakenGivenThrough();
          } else if (res.data.info == "no_users") {
            this.errorMessage(
              this.$t("equity_bond.messages.user_error_no_users")
            );
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondOrderTakenGivenThrough() {
      store
        .dispatch(
          "equity&bond/fetchArrangeEquityBondOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(this.$t("equity_bond.messages.creating_message"));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondCurrencies() {
      store
        .dispatch("equity&bond/fetchArrangeEquityBondCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optClientPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    searchForTrades(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$checkAccessRight("EquityBond", "Search").then((res) => {
        if (res.data.valid) {
          //set and check search data
          let searchData = this.setSearchData();

          store
            .dispatch("equity&bond/searchEquityBond", {
              auth: this.user,
              searchData: searchData,
            })
            .then((res) => {
              this.$emit("searchedData", res.data);
              this.saveLastSearchEquity(searchData);
              this.resetSearchForTradesModal();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.searchForTradesModal = false;
          this.notAuthToastMessage();
        }
      });
    },
    searchSecuritydesc(search) {
      if (search.length >= 3) {
        if (this.timerSecuritydesc) {
          clearTimeout(this.timerSecuritydesc);
          this.timerSecuritydesc = null;
        }

        this.timerSecuritydesc = setTimeout(() => {
          this.loadingSecurityDesc = true;
          let securityTypeVal = "Equity";

          return new Promise((resolve, reject) => {
            axiosIns
              .post(`searchSecuritydesc`, {
                auth: this.user,
                search: search,
                type: securityTypeVal,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.optSecurityDescriptions = [];

                  res.data.resData.forEach((element) => {
                    this.optSecurityDescriptions.push(element.SecurityTicker);
                  });
                  this.tradeForm.securityDesc =
                    res.data.resData[0].SecurityTicker;
                  this.tradeForm.isin = res.data.resData[0].SecurityIsin;
                  this.tradeForm.exchangeTraded = res.data.resData[0].Exchange;
                  this.tradeForm.clientPriceType =
                    res.data.resData[0].SecurityCcy;
                  this.loadingSecurityDesc = false;
                } else {
                  this.notFoundMessage("security desc.");
                  this.loadingSecurityDesc = false;
                }
              })
              .catch((error) => reject(error));
          });
        }, 800);
      }
    },
    selectedSecuritydesc(search) {
      this.tradeForm.isin = null;
      this.tradeForm.clientPriceType = null;
      this.tradeForm.exchangeTraded = null;

      let selecting = this.searchedSecurityDesc.find(
        (s) => s.SecurityTicker === search
      );
      this.tradeForm.isin = selecting.SecurityIsin;
      this.tradeForm.clientPriceType = selecting.SecurityCcy;
      this.tradeForm.quantity = selecting.DefaultShares;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedSecuritydescForExchange`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.exchangeTraded = null;
              // this.tradeForm.exchangeTraded = res.data.resData.Exchange;
              this.optExchangeTrades = [];
              if (res.data.resData.length > 0) {
                res.data.resData.forEach((element) => {
                  if (element != null) {
                    this.optExchangeTrades.push(element);
                  }
                });
              }
              this.checkCommission();
            }
          })
          .catch((error) => reject(error));
      });
    },
    getAllSecurityDesc() {
      axiosIns
        .post(`getAllVerifiedEQSecurities`)
        .then((res) => {
          if (res.data.length > 0) {
            this.optSecurityDescriptions = [];
            this.searchedSecurityDesc = [];
            this.searchedSecurityDesc = res.data;
            res.data.forEach((data) => {
              this.optSecurityDescriptions.push(data.SecurityTicker);
            });
          } else {
            this.notFoundMessage("security desc.");
          }
        })
        .catch((error) => console.log(error));
    },

    selectedClient(search) {
      this.tradeForm.clientAccount = null;

      let selecting = this.searchedClients.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.clientAccount = selecting.AccountNumber;
    },

    selectedCounterparty(search) {
      console.log(this.searchedCounterparties);
      this.tradeForm.counterparty = null;
      this.tradeForm.cptyAccount = null;
      this.tradeForm.counterpartyTrader = null;

      let selecting = this.searchedCounterparties.find(
        (s) => s.AccountName === search
      );
      this.tradeForm.cptyAccount = selecting.AccountNumber;

      return new Promise((resolve, reject) => {
        axiosIns
          .post(`selectedCounterpartyForTrader`, {
            auth: this.user,
            search: search,
          })
          .then((res) => {
            if (res.data.info == "Pass") {
              this.tradeForm.counterpartyTrader = res.data.resTrader[0].Trader;
              let listTraders = [];
              res.data.resTrader.forEach((element) => {
                if (!listTraders.includes(element.Trader)) {
                  listTraders.push(element.Trader);
                }
              });
              this.optCounterPartyTraders = listTraders;
            }
          })
          .catch((error) => reject(error));
      });
    },

    checkAccount() {
      if (this.tradeForm.client == null) {
        this.tradeForm.clientAccount = null;
      }
    },
    checkCptyAccount() {
      if (this.tradeForm.counterparty == null) {
        this.tradeForm.cptyAccount = null;
      }
    },

    checkEnableOCOInputs(input) {
      if (input == "takeProfitLimit") {
        if (this.tradeForm.takeProfitLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.take_profit_lower_stop_price")
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit < this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.take_profit_lower_limit_price")
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.stopLossStop > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.take_profit_greater_stop_price")
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
            if (
              this.tradeForm.takeProfitLimit > 0 &&
              this.tradeForm.stopLossLimit > 0
            ) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "equity_bond.messages.take_profit_greater_limit_price"
                  )
                );
                this.tradeForm.takeProfitLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("equity_bond.messages.greater_then_zero_take_profit")
          );
        }
      } else if (input == "stopLossStop") {
        if (this.tradeForm.stopLossStop > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossStop > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.stop_loss_greater_profit_price")
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossStop
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t("equity_bond.messages.stop_loss_lower_profit_price")
                );
                this.tradeForm.stopLossStop = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("equity_bond.messages.greater_then_zero_stop_loss")
          );
        }
      } else if (input == "stopLossLimit") {
        if (this.tradeForm.stopLossLimit > 0) {
          if (this.tradeForm.buySell == "Buy") {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.stopLossLimit > this.tradeForm.takeProfitLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "equity_bond.messages.stop_loss_limit_greater_profit_price"
                  )
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          } else if (
            this.tradeForm.buySell == "Sell" ||
            this.tradeForm.buySell == "Sell Short"
          ) {
            if (this.tradeForm.takeProfitLimit > 0) {
              if (
                this.tradeForm.takeProfitLimit > this.tradeForm.stopLossLimit
              ) {
                this.checkedEnableOCOInputsMessage(
                  this.$t(
                    "equity_bond.messages.stop_loss_limit_lower_profit_price"
                  )
                );
                this.tradeForm.stopLossLimit = null;
              }
            }
          }
        } else {
          this.greaterThanZeroMessage(
            this.$t("equity_bond.messages.greater_then_zero_stop_loss_limit")
          );
        }
      }
    },
    calculateAmount() {
      if (this.tradeForm.quantity > 0 && this.tradeForm.clientPrice > 0) {
        if (this.tradeForm.instrumentType == "EQ") {
          let cal = this.tradeForm.quantity * this.tradeForm.clientPrice;
          this.tradeForm.amount = parseFloat(cal.toFixed(2));
          this.tradeForm.clientAmount = cal;
          this.tradeForm.counterpartyAmount = cal;
        }
      }
    },
    calculateClientComission() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.clientRate > -1
      ) {
        if (this.tradeForm.instrumentType == "EQ") {
          this.tradeForm.clientCommission =
            (this.tradeForm.amount * this.tradeForm.clientRate) / 10000;
        }
        if (this.tradeForm.clientCommission > -1) {
          this.tradeForm.clientAmount = this.tradeForm.amount;
          this.tradeForm.clientAmount =
            this.tradeForm.counterpartyAmount + this.tradeForm.clientCommission;
          this.tradeForm.profit = this.tradeForm.clientCommission;
        }
      }
    },
    calculateCounterpartyComission() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.counterpartyRate > -1
      ) {
        if (this.tradeForm.instrumentType == "EQ") {
          this.tradeForm.cptyCommission =
            (this.tradeForm.amount * this.tradeForm.counterpartyRate) / 10000;
        }
        if (this.tradeForm.cptyCommission > -1) {
          this.tradeForm.counterpartyAmount = this.tradeForm.amount;
          this.tradeForm.counterpartyAmount =
            this.tradeForm.counterpartyAmount + this.tradeForm.cptyCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }
      }
    },
    calculateClientRate() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.clientCommission > -1
      ) {
        if (this.tradeForm.instrumentType == "EQ") {
          this.tradeForm.clientRate =
            (this.tradeForm.clientCommission / this.tradeForm.amount) * 10000;
        }

        if (this.tradeForm.clientRate > -1) {
          this.tradeForm.clientAmount = this.tradeForm.amount;
          this.tradeForm.clientAmount =
            this.tradeForm.clientAmount + this.tradeForm.clientCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }
      }
    },
    calculateCounterpartyRate() {
      if (
        this.tradeForm.quantity > 0 &&
        this.tradeForm.clientPrice > 0 &&
        this.tradeForm.cptyCommission > -1
      ) {
        if (
          this.tradeForm.amount > 0 &&
          this.tradeForm.instrumentType == "EQ"
        ) {
          this.tradeForm.counterpartyRate =
            (this.tradeForm.cptyCommission / this.tradeForm.amount) * 10000;
        }

        if (this.tradeForm.counterpartyRate > -1) {
          this.tradeForm.counterpartyAmount = this.tradeForm.amount;
          this.tradeForm.counterpartyAmount =
            this.tradeForm.counterpartyAmount + this.tradeForm.cptyCommission;
          this.tradeForm.profit =
            this.tradeForm.clientCommission +
            this.tradeForm.cptyCommission * -1;
        }
      }
    },
    calculateConterpartyFaceValue() {
      if (this.tradeForm.quantity > 0) {
        if (
          this.tradeForm.clientPrice > 0 &&
          this.tradeForm.instrumentType == "EQ"
        ) {
          return this.tradeForm.quantity * this.tradeForm.clientPrice;
        } else {
          return 0;
        }
      }
    },
    calculateClientAmountTax() {
      let temp = 0;

      if (this.tradeForm.amount > 0) {
        temp = temp + this.tradeForm.amount;
      }

      if (this.tradeForm.clientCommission > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = temp + this.tradeForm.clientCommission;
        } else {
          temp = temp - this.tradeForm.clientCommission;
        }
      }

      if (this.tradeForm.tax > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = temp + this.tradeForm.tax;
        } else {
          temp = temp - this.tradeForm.tax;
        }
      }

      if (temp > 0) {
        this.tradeForm.clientAmount = temp;
        this.tradeForm.counterpartyAmount = temp;
      } else {
        this.tradeForm.counterpartyAmount = null;
      }
    },
    calculateCounterpartyAmount() {
      let temp = 0;

      temp = temp + this.calculateConterpartyFaceValue();

      if (this.tradeForm.counterpartyComission > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = temp + this.tradeForm.counterpartyComission;
        } else {
          temp = temp - this.tradeForm.counterpartyComission;
        }
      }

      if (this.tradeForm.tax > 0) {
        if (this.tradeForm.buySell == "Buy") {
          temp = temp + this.tradeForm.tax;
        } else {
          temp = temp - this.tradeForm.tax;
        }
      }

      if (temp > 0) {
        this.tradeForm.clientAmount = temp;
        this.tradeForm.counterpartyAmount = temp;
      } else {
        this.tradeForm.counterpartyAmount = null;
      }
    },
    calculateTCProfit() {
      let temp = 0;

      if (
        this.tradeForm.clientAmount > 0 &&
        this.tradeForm.counterpartyAmount > 0
      ) {
        if (this.tradeForm.buySell == "Buy") {
          this.tradeForm.profit =
            this.tradeForm.clientAmount - this.tradeForm.counterpartyAmount;
        } else {
          this.tradeForm.profit =
            -this.tradeForm.clientAmount + this.tradeForm.counterpartyAmount;
        }
      } else {
        this.tradeForm.profit = null;
      }
    },
    checkTraSettlCptyDate(type) {
      if (type == "Trade Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.tradeDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_before_order_date")
          );
          this.tradeForm.tradeDate = null;
        }
      } else if (type == "Settlement Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.trade_date_after_settlement_date")
          );
          this.tradeForm.settlementDate = null;
        }
      } else if ("Cpty Order Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.tradeDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "equity_bond.messages.trade_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }

        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.settlementDate)) <
          Date.parse(this.YMDdateFormat(this.tradeForm.cptyOrderDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t(
              "equity_bond.messages.settlement_date_before_counterparty_order_date"
            )
          );
          this.tradeForm.cptyOrderDate = null;
        }
      }
    },
    checkTime(type) {
      if (type == "Order Time") {
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }

        if (
          this.tradeForm.orderTimeMax != null &&
          this.tradeForm.orderTimeMax.length < 8
        ) {
          for (let i = this.tradeForm.orderTimeMax.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTimeMax += ":";
            } else if (i == 5) {
              this.tradeForm.orderTimeMax += ":";
            } else {
              this.tradeForm.orderTimeMax += "0";
            }
          }
        }
        this.checkOrdersTime("Order Time");
      } else if ("Execution Time") {
        if (
          this.tradeForm.executionTime != null &&
          this.tradeForm.executionTime.length < 8
        ) {
          for (let i = this.tradeForm.executionTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.executionTime += ":";
            } else if (i == 5) {
              this.tradeForm.executionTime += ":";
            } else {
              this.tradeForm.executionTime += "0";
            }
          }
        }

        if (
          this.tradeForm.executionTimeMax != null &&
          this.tradeForm.executionTimeMax.length < 8
        ) {
          for (let i = this.tradeForm.executionTimeMax.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.executionTimeMax += ":";
            } else if (i == 5) {
              this.tradeForm.executionTimeMax += ":";
            } else {
              this.tradeForm.executionTimeMax += "0";
            }
          }
        }
      }
    },
    checkOrdersTime(type) {
      if (type == "Order Duration Time") {
        if (
          this.tradeForm.orderDurationTime != null &&
          this.tradeForm.orderDurationTime.length < 8
        ) {
          for (let i = this.tradeForm.orderDurationTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderDurationTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderDurationTime += ":";
            } else {
              this.tradeForm.orderDurationTime += "0";
            }
          }
        }

        if (
          this.tradeForm.orderDurationTimeMax != null &&
          this.tradeForm.orderDurationTimeMax.length < 8
        ) {
          for (let i = this.tradeForm.orderDurationTimeMax.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderDurationTimeMax += ":";
            } else if (i == 5) {
              this.tradeForm.orderDurationTimeMax += ":";
            } else {
              this.tradeForm.orderDurationTimeMax += "0";
            }
          }
        }
      } else if (type == "Order Time") {
        if (
          this.tradeForm.orderTime != null &&
          this.tradeForm.orderTime.length < 8
        ) {
          for (let i = this.tradeForm.orderTime.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTime += ":";
            } else if (i == 5) {
              this.tradeForm.orderTime += ":";
            } else {
              this.tradeForm.orderTime += "0";
            }
          }
        }

        if (
          this.tradeForm.orderTimeMax != null &&
          this.tradeForm.orderTimeMax.length < 8
        ) {
          for (let i = this.tradeForm.orderTimeMax.length; i < 8; i++) {
            if (i == 2) {
              this.tradeForm.orderTimeMax += ":";
            } else if (i == 5) {
              this.tradeForm.orderTimeMax += ":";
            } else {
              this.tradeForm.orderTimeMax += "0";
            }
          }
        }
      }
    },
    checkOrdersDate(type) {
      if (type == "Order Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.order_date_after_order_duration_date")
          );
          this.tradeForm.orderDate = null;
        }
      } else if (type == "Order Duration Date") {
        if (
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDate)) >
          Date.parse(this.YMDdateFormat(this.tradeForm.orderDurationDate))
        ) {
          this.checkedTraSettlDateMessage(
            this.$t("equity_bond.messages.order_duration_less_order_date")
          );
          this.tradeForm.orderDurationDate = null;
        }
      }
    },
    changeTimeInForceType() {},
    clearOrderDate() {
      this.orderDateRange = null;
      this.tradeForm.orderDate = null;
      this.tradeForm.orderDateEnd = null;
    },
    clearDurationDate() {
      this.durationDateRange = null;
      this.tradeForm.orderDurationDate = null;
      this.tradeForm.orderDurationDateEnd = null;
    },
    clearTradeDate() {
      this.tradeDateRange = null;
      this.tradeForm.tradeDate = null;
      this.tradeForm.tradeDateEnd = null;
    },
    clearSettleDate() {
      this.settleDateRange = null;
      this.tradeForm.settlementDate = null;
      this.tradeForm.settlementDateEnd = null;
    },
    resetSearchForTradesModal() {
      this.searchForTradesModal = false;
      this.optSecurityDescriptions = [];
      this.optClientPriceTypes = [];
      this.optExchangeTrades = [];
      this.optCounterPartyTraders = [];
      this.optScfUsers = [];
      this.optOrderTakenVias = [];
      this.optOrderGivenThroughs = [];
      this.showSearchInputs = false;
      this.tradeForm = {
        costCenter: null,
        orderDurationDate: null,
        orderDurationDateEnd: null,

        orderDurationTime: null,
        orderDurationTimeMax: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderDateEnd: null,

        orderTime: null,
        orderTimeMax: null,
        cptyOrderDate: null,
        cptyOrderDateEnd: null,
        enableConnectedOCOOrders: "All Oco Orders",
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        instrumentType: "EQ",
        prop: false,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: null,
        tradeDate: null,
        tradeDateEnd: null,

        settlementDate: null,
        settlementDateEnd: null,

        executionTime: null,
        executionTimeMax: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        tax: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,

        stopPriceEnd: null,
        trailingAmountEnd: null,
        takeProfitLimitEnd: null,
        stopLossStopEnd: null,
        stopLossLimitEnd: null,
        quantityEnd: null,
        clientPriceEnd: null,
        amountEnd: null,
        calculateCommissionFromRate1End: null,
        clientRateEnd: null,
        clientCommissionEnd: null,
        clientAmountEnd: null,
        calculateCommissionFromRate2End: null,
        counterpartyRateEnd: null,
        cptyCommissionEnd: null,
        counterpartyAmountEnd: null,
        profitEnd: null,
        taxEnd: null,
      };

      this.orderDateRange = null;
      this.tradeDateRange = null;
      this.settleDateRange = null;
      this.durationDateRange = null;
    },
    greaterThanZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `${val} must be greater than 0`,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedEnableOCOInputsMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("equity_bond.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    checkedTraSettlDateMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTimeMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: `The searched ${val} data is not found`,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    successMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Success",
          text: message,
          icon: "TickIcon",
          variant: "success",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    setSearchData() {
      let setSearch = {
        costCenter: this.tradeForm.costCenter,
        instrumentType: "EQ",
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,
        tradeDateEnd:
          this.tradeForm.tradeDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.tradeDateEnd)
            : null,
        settlementDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        settlementDateEnd:
          this.tradeForm.settlementDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.settlementDateEnd)
            : null,
        orderTime:
          this.tradeForm.orderTime != null
            ? this.timeFormat(this.tradeForm.orderTime)
            : null,
        orderTimeMax:
          this.tradeForm.orderTimeMax != null
            ? this.timeFormat(this.tradeForm.orderTimeMax)
            : null,

        executionTime:
          this.tradeForm.executionTime != null
            ? this.timeFormat(this.tradeForm.executionTime)
            : null,
        executionTimeMax:
          this.tradeForm.executionTimeMax != null
            ? this.timeFormat(this.tradeForm.executionTimeMax)
            : null,

        client: this.tradeForm.client,
        account: this.tradeForm.clientAccount,
        securityDesc: this.tradeForm.securityDesc,
        isin: this.tradeForm.isin,
        buySell: this.tradeForm.buySell,
        quantity:
          this.tradeForm.quantity != null ? this.tradeForm.quantity : null,
        clientAmount:
          this.tradeForm.clientAmount != null
            ? parseFloat(this.tradeForm.clientAmount.toFixed(2))
            : null,
        counterpartyRate:
          this.tradeForm.counterpartyRate != null
            ? parseFloat(this.tradeForm.counterpartyRate.toFixed(2))
            : null,
        comissionRate:
          this.tradeForm.clientRate != null
            ? parseFloat(this.tradeForm.clientRate.toFixed(2))
            : null,
        counterpartyComission:
          this.tradeForm.cptyCommission != null
            ? parseFloat(this.tradeForm.cptyCommission.toFixed(2))
            : null,
        commissionAmount:
          this.tradeForm.clientCommission != null
            ? parseFloat(this.tradeForm.clientCommission.toFixed(2))
            : null,
        counterpartyAmount:
          this.tradeForm.counterpartyAmount != null
            ? parseFloat(this.tradeForm.counterpartyAmount.toFixed(2))
            : null,
        counterpartyAccount: this.tradeForm.cptyAccount,
        counterpartyTrader: this.tradeForm.counterpartyTrader,
        ccy: this.tradeForm.clientPriceType,
        price:
          this.tradeForm.clientPrice != null
            ? parseFloat(this.tradeForm.clientPrice.toFixed(2))
            : null,
        amount:
          this.tradeForm.amount != null
            ? parseFloat(this.tradeForm.amount.toFixed(2))
            : null,
        counterparty: this.tradeForm.counterparty,
        tcUser: this.tradeForm.scfUser,
        orderTakenVia: this.tradeForm.orderTakenVia,
        orderGivenThrough: this.tradeForm.orderGivenThrough,
        orderPrice: null,
        exchangeTraded: this.tradeForm.exchangeTraded,
        traderNote: this.tradeForm.note,
        securityAccount: null,
        actingCapaticy: this.tradeForm.actingCapaticy,
        tax:
          this.tradeForm.tax != null
            ? parseFloat(this.tradeForm.tax.toFixed(2))
            : null,
        tcUti: this.tradeForm.uti,
        uniqueLinkId: this.tradeForm.uniqueLinkID,
        tcProfit:
          this.tradeForm.profit != null
            ? parseFloat(this.tradeForm.profit.toFixed(2))
            : null,
        entryType: this.tradeForm.entryType,
        orderType: this.tradeForm.orderType,
        timeInForce: this.tradeForm.timeInForce,
        stopPrice:
          this.tradeForm.stopPrice != null
            ? parseFloat(this.tradeForm.stopPrice.toFixed(2))
            : null,
        trailingAmount:
          this.tradeForm.trailingAmount != null
            ? parseFloat(this.tradeForm.tradeForm.toFixed(2))
            : null,
        takeProfit: null,
        stopLoss:
          this.tradeForm.stopLossStop != null
            ? parseFloat(this.tradeForm.stopLossStop.toFixed(2))
            : null,
        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,
        orderDateEnd:
          this.tradeForm.orderDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.orderDateEnd)
            : null,
        counterpartyOrderDate:
          this.tradeForm.cptyOrderDate != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDate)
            : null,
        counterpartyOrderDateEnd:
          this.tradeForm.cptyOrderDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDateEnd)
            : null,
        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationDateEnd:
          this.tradeForm.orderDurationDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDateEnd)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.timeFormat(this.tradeForm.orderDurationTime)
            : null,
        orderDurationTimeMax:
          this.tradeForm.orderDurationTimeMax != null
            ? this.timeFormat(this.tradeForm.orderDurationTimeMax)
            : null,

        enableOCO: this.tradeForm.enableConnectedOCOOrders,
        ocoType: null,
        takeProfitLimit:
          this.tradeForm.takeProfitLimit != null
            ? parseFloat(this.tradeForm.takeProfitLimit.toFixed(2))
            : null,
        stopLossLimit:
          this.tradeForm.stopLossLimit != null
            ? parseFloat(this.tradeForm.stopLossLimit.toFixed(2))
            : null,
        ocoLink: null,
        orderQuantity: null,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        future16: null,
        startTradeDate:
          this.tradeForm.tradeStart != null
            ? this.YMDdateFormat(this.tradeForm.tradeStart)
            : null,
        endTradeDate:
          this.tradeForm.tradeEnd != null
            ? this.YMDdateFormat(this.tradeForm.tradeEnd)
            : null,
        startSettlementDate:
          this.tradeForm.settleStart != null
            ? this.YMDdateFormat(this.tradeForm.settleStart)
            : null,
        endSettlementDate:
          this.tradeForm.settleEnd != null
            ? this.YMDdateFormat(this.tradeForm.settleEnd)
            : null,
        ID: 0,

        stopPriceEnd: this.tradeForm.stopPriceEnd,
        trailingAmountEnd: this.tradeForm.trailingAmountEnd,
        takeProfitLimitEnd: this.tradeForm.takeProfitLimitEnd,
        stopLossEnd: this.tradeForm.stopLossStopEnd,
        stopLossLimitEnd: this.tradeForm.stopLossLimitEnd,
        quantityEnd: this.tradeForm.quantityEnd,
        priceEnd:
          this.tradeForm.clientPriceEnd != null
            ? parseFloat(this.tradeForm.clientPriceEnd.toFixed(2))
            : null,
        amountEnd: this.tradeForm.amountEnd,
        calculateCommissionFromRate1End: this.tradeForm
          .calculateCommissionFromRate1End,
        comissionRateEnd: this.tradeForm.clientRateEnd,
        commissionAmountEnd: this.tradeForm.clientCommissionEnd,
        clientAmountEnd: this.tradeForm.clientAmountEnd,
        calculateComissionFromRate2End: this.tradeForm
          .calculateCommissionFromRate2End,
        counterpartyRateEnd: this.tradeForm.counterpartyRateEnd,
        counterpartyComissionEnd: this.tradeForm.cptyCommissionEnd,
        counterpartyAmountEnd: this.tradeForm.counterpartyAmountEnd,
        tcProfitEnd: this.tradeForm.profitEnd,
        taxEnd: this.tradeForm.taxEnd,
      };

      return setSearch;
    },
  },
  watch: {
    searchForTradesModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeEquityBondTCUsers();
          this.getArrangeEquityBondCurrencies();
          // hack textare row count
          setTimeout(function() {
            let textarea = document.getElementById("form-textarea");
            if (textarea) {
              textarea.rows = 1;
            }
          }, 200);
        }
      },
    },

    orderDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.orderDate = val.split("to")[0];
            this.tradeForm.orderDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.orderDate = val;
          }
        }
      },
    },

    tradeDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.tradeDate = val.split("to")[0];
            this.tradeForm.tradeDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.tradeDate = val;
          }
        }
      },
    },

    settleDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.settlementDate = val.split("to")[0];
            this.tradeForm.settlementDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.settlementDate = val;
          }
        }
      },
    },

    durationDateRange: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.tradeForm.orderDurationDate = val.split("to")[0];
            this.tradeForm.orderDurationDateEnd = val.split("to")[1];
          } else {
            this.tradeForm.orderDurationDate = val;
          }
        }
      },
    },
  },
};
</script>

<style>
#searchTradeModal .modal-header .modal-title {
  color: white !important;
}

.eq_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #191970;
}

.fi_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #cd853f;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

// multiple vue select input
.v-select:not(.vs--single) .vs__selected {
  font-size: 0.6rem !important;
}
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "../../assets/scss/compact-design.scss";

// multiple vue select input
.compact_form_vue-multi-select::v-deep .vs__selected {
  position: relative !important;
}

//
</style>
